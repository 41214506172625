.image-gallery-item {
    background-color: #f9fafd;
    margin: 5px;
    width: 150px;
    height: 150px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    border: 1px solid #ddd;
}

.image-gallery-toolbar {
    width: 100%;
    height: 30px;
    margin-top: calc(var(--falcon-gutter-x) * 0.5);
}

.image-gallery-delete-btn {
    border-radius: 50%;
    width: 30px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-library-add-btn {
    border-radius: 50%;
    width: 30px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-gallery-primary-badge {
    height: 100%;;
    font-size: 14px;
    visibility: hidden;
}

.image-gallery-placeholder {
    background-color: #f9fafd;
    margin: 5px;
    width: 150px;
    height: 150px;
    border: 1px solid #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.image-gallery-placeholder-sm {
    background-color: #f9fafd;
    margin: 5px;
    width: 75px;
    height: 75px;
    border: 1px solid #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.primary-image {
    background-color: #f9fafd;
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
}

.image-gallery-scrollable {
    overflow-y: scroll;
    max-height: 300px;
}

.suggestion-container {
    z-index: 9999;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    display: none;
}

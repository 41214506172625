@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

@import "variables";

@import "theme/theme";

@import "~select2/src/scss/core";

@import '~jqtree/jqtree';

@import 'bootstrap-table/src/bootstrap-table';

@import "other/image-gallery.scss";

@import "other/file-handler.scss";

@import "other/popup.scss";

@import "other/import.scss";

.card-header .card-title {
    margin-bottom: 0;
    color: $primary;
    font-weight: bold;
}

.lang-icon {
    width: 25px;
    height: 25px;
    margin-right: 5px;
}

.treeview-stripe>li:nth-child(even) {
    background-color: $light;
}

.select-multiple.is-invalid:not(.select2-container) .select2-selection {
    border-color: $danger !important;
}

:root {
    --falcon-font-sans-serif: 'Open sans', sans-serif;
    --falcon-gray-200: #EFF0F2;
    --falcon-light-rgb: 239, 240, 242;
    --falcon-dark-rgb: 63, 76, 88;
    --falcon-card-color: --falcon-heading-color;
    --falcon-body-color: --falcon-heading-color;
    --falcon-top-nav-height: 6.5rem;
}

:root .table-light,
[data-bs-theme=light] .table-light {
    --falcon-table-bg: #fff;
    --falcon-table-color: $headings;
    --falcon-table-hover-bg: #EFF0F2;
    --falcon-table-striped-bg: #fff;
    --falcon-card-cap-color: --falcon-heading-color;
}

h1 {
    font-weight: 600;
}

hr {
    color: #ced1d5;
}

// General styling
.card {
    box-shadow: none;
    --falcon-card-cap-bg: rgba(255, 255, 255, 1) !important;
}

.card-body {
    background-color: #fff;
    border-radius: 0.375rem;
}

.card-header .card-title {
    color: $headings;
}

.btn {
    border-radius: 32px;
    font-weight: 600;
}

.badge.bg-secondary {
    color: $headings;
}

.badge.badge-outline {
    border: 1px solid $border;
    color: $headings;
}

.badge.badge-outline-success {
    border: 1px solid $success;
    color: $headings;
}

.badge.badge-outline-danger {
    border: 1px solid $danger;
    color: $headings;
}

.badge.badge-outline-warning {
    border: 1px solid $warning;
    color: $headings;
}

.btn.btn-outline-dark {
    border-color: $border-color;
    color: $headings;
}

.btn.btn-outline-dark:hover {
    background-color: $primary;
    color: #fff;
    ;
}

@media only screen and (min-width: 1200px) {
    .collapse.navbar-collapse {
        margin-top: 12px !important;
        width: 100% !important;
        background: transparent;
        overflow: visible !important;
    }

    .navbar-vertical.navbar-expand-xl {
        max-width: 16rem;
        padding: 12px 20px;
        background-color: #1D3763;
        border-top: 4px solid $danger;
    }

    .navbar-vertical.navbar-expand-xl+.content {
        margin-left: 16rem;
    }

    .navbar-vertical.navbar-expand-xl .navbar-vertical-content {
        width: 100%;
        padding-top: 0;
    }

    .navbar .nav-item .nav-link {
        font-weight: 600;
        padding: 5px 8px;
        border-radius: 4px;
        color: rgba(255, 255, 255, .7) !important;
    }

    .navbar .nav-item .nav-link.active {
        background-color: rgba(255, 255, 255, .1);
        color: #fff !important;
        border-left: 3px solid $danger;
    }

    .navbar-vertical .navbar-nav .nav-item .nav-link:hover,
    .navbar-vertical .navbar-nav .nav-item .nav-link:focus {
        color: #fff;
    }

    .navbar-vertical .navbar-collapse .navbar-vertical-content>* {
        overflow: visible !important;
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
        right: unset !important;
        bottom: 55px;
        top: unset;
        border: none;
    }

    .navbar-vertical-content .navbar-nav.bottom-actions {
        padding: 0 20px;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
    }

    .navbar-vertical-content .navbar-nav.bottom-actions .nav-item {
        padding: 8px 0;
    }

    .navbar-vertical-content .navbar-nav.bottom-actions .nav-item.transoplast-language-dropdown {
        border-right: 1px solid rgba(255, 255, 255, 0.2);
        min-width: fit-content;
    }
}

.transoplast-language-dropdown .dropdown-menu .dropdown-item .lang-item {
    border-radius: 4px;
    padding: 6px 9px;
}

.transoplast-language-dropdown .dropdown-menu .dropdown-item .lang-item.is-active {
    pointer-events: none;
}

.transoplast-language-dropdown .dropdown-menu .dropdown-item .lang-item .lang-icon {
    width: 20px;
    height: 20px;
}

// Table styling
.product-overview-table tr {
    border-color: $border;
    border-style: solid;
    border-width: 1px;
}

.product-overview-table thead th {
    background-color: $secondary;
    color: $headings;
    font-size: 14px;
    padding: 8px;
}

.product-overview-table tbody td {
    padding: 8px;
    color: $text;
}

.product-overview-table .is-name {
    font-weight: 600;
    color: $headings;
}

.product-overview-table .is-date {
    font-size: 13px;
}

.product-overview-table .is-actions {
    vertical-align: middle;
}

.product-overview-table .is-actions .btn {
    font-size: 14px;
}

.product-overview-table .is-actions .d-flex {
    column-gap: 5px;
}

.table.table-light.table-striped {
    border-color: $border;
    border-style: solid;
    border-width: 1px;
}

.table.table-light.table-striped thead th {
    background-color: $secondary;
    color: $headings;
    font-size: 14px;
    padding: 8px;
}

.table.table-light.table-striped tbody td {
    padding: 8px;
    color: $text;
}


// JQtree
.jqtree-tree>.jqtree_common,
.jqtree-tree .jqtree-folder {
    border: 1px solid $border;
    border-radius: .275rem;
    padding: 8px;
    margin-bottom: 8px !important;
}

.jqtree-tree .jqtree-folder:last-child {
    margin-bottom: 0 !important;
}

.jqtree-tree .jqtree-element {
    align-items: center;
}

.jqtree-tree .jqtree-moving>.jqtree-element .jqtree-title {
    outline: dashed 1px $primary !important;
}

.jqtree-tree .jqtree-element-controllers .btn.btn-sm {
    font-size: 11px;
    padding: 4px 8px;
    line-height: 1;
}

.jqtree-folder>.jqtree-dnd>.jqtree-folder {
    margin-top: 8px;
}

.jqtree-folder>.jqtree-dnd:not(:has(.jqtree-folder)) {
    border-top: 1px solid $border;
    padding: 8px;
    margin-top: 8px;
    margin-left: 0;
    padding-left: 0;
}

.form-control {
    padding: 0.3125rem .8rem;
    box-shadow: none !important;
}

.form-select {
    box-shadow: none !important;
}

// Product detail
.product-sync-status {
    font-size: 14px;
}


.is-dashboard .quick-actions .action {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    align-items: center;
    row-gap: 12px;
    padding: 16px;
    border: 1px solid $border;
    margin-bottom: 2rem;
}

.is-dashboard .quick-actions .action .fas {
    font-size: 16px;
}

.is-dashboard .quick-actions .action h3 {
    font-size: 14px;
    font-weight: 600;
}

.input-group .input-group-text {
    min-width: 55px;
    justify-content: center;
}

.category-handling-notice {
    background-color: transparent !important;
    border: 1px solid rgba(0, 0, 0, .1);
}

.category-handling-notice .card-body {
    background-color: transparent !important;
}

.navbar-blue {
    background-color: rgba(255, 255, 255, .1);
}

.link-row {
    margin-bottom: 2rem;

    h5 {
        font-weight: 600;
        font-size: 1rem;
    }
}

.nav-item:hover {
    >.nav-link .nav-link-text {
        color: #fff;
    }
}

.progress-container {
    width: 100%;
    background-color: #ccc;
    height: 25px;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.progress-bar {
    width: 0;
    height: 100%;
    background-color: $primary;
    border-radius: 5px;
    transition: width 0.3s ease-in-out;
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 40px 40px;
}

.progress-bar__text {
    color: #fff;
    font-weight: bold;
}

.categories-select__dropdown {
    max-height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.categories-select {
    border: #ccc 0.5px solid;
    border-radius: 4px;
    padding: 5px 10px;
    position: relative;
    display: inline-block;
    width: 100%;

    .categories-select__input:checked+.form-check-label {
        padding: 5px 10px;
        background-color: $primary;
        color: #fff;
        border-radius: 4px;
        font-weight: 900;
        margin-top: 5px;
    }

    .categories-select__input {
        position: absolute;
        left: -9999px;
        margin-left: 0;
        display: block;
    }

    .categories-select__children {
        display: none;
        margin-left: 20px;
    }
}

.has-tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;

    &:hover::before {
        content: attr(title);
        position: absolute;
        background-color: rgba(0, 0, 0, 0.7);
        color: #fff;
        padding: 4px 8px;
        border-radius: 4px;
        white-space: nowrap;
        z-index: 1;
        bottom: 120%;
        /* Position the tooltip above the element */
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s ease-in-out;
    }

    &:hover::before {
        opacity: 1;
    }
}

.select2-selection.select2-selection--multiple {
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 5px 10px;
    width: 290px !important;
}

.archive-image__container {
    border: 1px dashed lightgray;
    min-height: 40vh;
    min-width: 50vw;
    max-width: 90vw;
    max-height: 70vh;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.archive-image__preview {
    width: auto;
    height: 100%;
    max-height: 50vh;
    display: block;
    object-fit: cover;
}

.environment-alert {
    position: fixed;
    display: flex;
    bottom: 0;
    right: 0;
    z-index: 9999;
    background-color: #f75d6c;
    color: #fff;
    border-radius: 4px 0 0 0;
    padding: 10px;
    font-size: x-small;
    opacity: 0.65;

    span {
        font-weight: bold;
        text-transform: uppercase;
        display: flex;      
    }

    img {
        vertical-align: middle;
        width: 15px;
        height: 15px;
        margin-right: 5px;
    }

    a {
        text-decoration: none;
        color: #fff;
        display: block;
        border-left: 1px solid #fff;
        padding-left: 10px;
        margin-left: 10px;
    }
}

.swal-wide {
    width: 850px !important;
}
/*-----------------------------------------------
|   Sidebar
-----------------------------------------------*/
.sticky-sidebar {
  position: sticky;
  z-index: $zindex-sticky;
  top: calc($top-nav-height + 1rem);
}

.top-navbar-height {
  top: $top-nav-height;
}

/*-----------------------------------------------
|   Custom circle
-----------------------------------------------*/
.circle-dashed {
  border: 1px dashed var(--#{$prefix}primary);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
}

/*-----------------------------------------------
|   Miscellaneous
-----------------------------------------------*/

.hover-text-decoration-none {
  @include hover-focus {
    text-decoration: none;
  }
}

.resize-none {
  resize: none;
}

.collapsed .collapse-icon {
  transition: $transition-base;
  transform: rotate(0deg);
}

.collapse-icon {
  transition: $transition-base;
  transform: rotate(90deg);
}

[data-dismiss="dropdown"],
[data-bs-offset-top],
[data-bs-toggle="collapse"],
[data-bs-toggle="tooltip"],
[data-bs-toggle="popover"] {
  * {
    pointer-events: none;
  }
}

/*-----------------------------------------------
|   Outline
-----------------------------------------------*/

.outline-none {
  outline: none;
  box-shadow: none;

  @include hover-focus {
    outline: none;
    box-shadow: none;
  }
}

/*-----------------------------------------------
|   Vertical Line (used in kanban header)
-----------------------------------------------*/
.vertical-line {
  &:after {
    position: absolute;
    content: "";
    height: 75%;
    width: 1px;
    background: var(--#{$prefix}gray-300);
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &.vertical-line-400 {
    &:after {
      background-color: var(--#{$prefix}gray-400);
    }
  }
}

/* -------------------------------------------------------------------------- */
/*                                 Transition                                 */
/* -------------------------------------------------------------------------- */
.fsp-75 {
  font-size: 75%;
}

/* -------------------------------------------------------------------------- */
/*                                    Width                                   */
/* -------------------------------------------------------------------------- */

// used in chat
.min-w-0 {
  min-width: 0;
}

/* -------------------------------------------------------------------------- */
/*                                Terms sidebar                               */
/* -------------------------------------------------------------------------- */

.terms-sidebar {
  .nav-link {
    color: $gray-500;
    font-weight: $font-weight-medium;
    font-family: var(--#{$prefix}font-sans-serif);

    &.active {
      color: var(--#{$prefix}primary);
    }
  }
}


.divider-content-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding-left: map-get($spacers, 2);
  padding-right: map-get($spacers, 2);
  background-color: var(--#{$prefix}divider-content-bg);
  font-family: var(--#{$prefix}font-sans-serif);
  font-size: map-get($font-sizes, '-1');
  color: var(--#{$prefix}gray-500);
  white-space: nowrap;
}

.btn-check[data-theme-control]:checked+label {
  display: none;
}

/* -------------------------------------------------------------------------- */
/*                                    Height                                  */
/* -------------------------------------------------------------------------- */
.content {
  min-height: 100vh;
  position: relative;
  padding-bottom: 6.375rem;

  @include media-breakpoint-up(sm) {
    padding-bottom: $footer-height;
  }
}
.navbar-top + .content{
  min-height: calc(100vh - $top-nav-height);
}
.footer{
  position: absolute;
  bottom: 0;
  width: 100%;
}
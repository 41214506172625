div.import {
    label.file-input {
        display: block;
        margin: 0 0 1em;
        border: 1px dashed #ccc;
        padding: 1em;
        text-align: center;
        height: 200px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.disabled {
            opacity: 0.5;
        }
    }

    .step-description {
        font-size: larger;
        margin-left: 0.5rem;
    }
}
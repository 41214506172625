.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 600px;
    background-color: transparent;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    z-index: 1021;

    .card-body {
        max-height: 75vh;
        overflow-y: auto;    
    }
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1020;
    display: block;
}

@media (max-width: 600px) {
    .popup {
        width: 80vw;
    }
}
